<template>
  <v-dialog ref="dialog" v-model="modal" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        v-bind="attrs"
        v-on="on"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" range scrollable>
      <v-btn text color="primary" @click="approved">אישור</v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="cancel">ביטול</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: "DateRangePicker",
  props: { value: Array, label: String },
  data: () => ({
    oldDates: [],
    modal: false,
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    dates: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    approved() {
      this.$emit("approved");
      this.modal = false;
    },
    cancel() {
      this.dates = [...this.oldDates];
      this.modal = false;
    },
  },
  watch: {
    modal(v) {
      if (v) {
        this.oldDates = [...this.dates];
      }
    },
  },
};
</script>
<style scoped lang="scss">
:deep(input) {
  text-align-last: center;
}
</style>
