import dayjs from "dayjs";

export const formatDate = (date, format = "DD/MM/YYYY") => {
  return dayjs(date).format(format);
};
export const timeFormat = (date, format = "HH:mm") => {
  return dayjs(date).format(format);
};
export const dateTimeFormat = (date, format = "DD/MM/YYYY \n HH:mm") => {
  return dayjs(date).format(format);
};

export default dayjs;
