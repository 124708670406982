<template>
  <div class="home header-color">
    <my-header btnRight="mdi-menu" :title="title" @clickBtnRight="openMenu" />

    <v-container
      class="form-container d-flex justify-center flex-column body-color"
    >
      <div class="d-flex justify-center">
        <div class="avatar-img d-flex justify-center mt-n16">
          <v-avatar size="126" class="avatar" color="white">
            <img
              class="pa-3"
              src="/imgs/company.png"
              lazy-src="/imgs/company.png"
            />
          </v-avatar>
        </div>
      </div>
      <div class="hllow-user d-flex justify-center">
        שלום,
        <br />
        {{ company.name }}
      </div>
      <slot name="content" />
    </v-container>
  </div>
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";

export default {
  name: "CompanyHeader",
  components: {
    MyHeader,
  },
  props: ["title"],
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  methods: {
    openMenu() {
      this.$store.commit("drawer/set", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.home > .header {
  height: 160px;
}
</style>
